import type { KeyValuePairsAllStrings } from '../types/utilTypes';

const CHART_CATEGORIES: KeyValuePairsAllStrings = {
  'Managing Workload': 'other.sectionDesc.0.title',
  'Psychological Safety at Work': 'other.sectionDesc.1.title',
  'Social Interaction at Work': 'other.sectionDesc.2.title',
  'Organisational Support': 'other.sectionDesc.3.title',
  'Career Development': 'other.sectionDesc.4.title',
  'External Factors': 'other.sectionDesc.5.title',
  'Your Results': 'results.yourResults',
  'Cohort Results': 'results.cohortResults',
};

export default CHART_CATEGORIES;
