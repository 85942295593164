import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './PageNav.module.scss';
import PAGE_LINKS from './pageLinks';

interface PaveNavProps {
  showCompanyTab: boolean;
}

const PageNav = ({ showCompanyTab }: PaveNavProps) => {
  const { t } = useTranslation();
  const filteredPageLinks = showCompanyTab ? PAGE_LINKS : PAGE_LINKS.slice(0, 3);
  const links = filteredPageLinks.map(({ name, pageLink }) => (
    <NavLink
      key={name}
      className={(navData) => (navData.isActive ? styles.selected : '')}
      to={pageLink}
    >
      <li className={styles.link}>{t(name)}</li>
    </NavLink>
  ));

  return (
    <div className={styles.pageHeader}>
      <ul className={styles.pageLinks}>{links}</ul>
    </div>
  );
};

export default PageNav;
