/* eslint-disable @typescript-eslint/no-explicit-any */
import { StyleSheet } from '@react-pdf/renderer';

interface PDFStylesTypes {
  body?: object;
  header?: object;
  logo?: object;
  productTitle?: object;
  headerSmall?: object;
  text?: object;
  recommendation?: object;
  recommendationHeader?: object;
  recommendationSubHeader?: object;
  recommendationText?: object;
  pageNumber?: object;
  sessionID?: object;
  chartContainer?: object;
  chart1?: object;
  chart2?: object;
  chartHeader?: object;
  chartP?: object;
  legendContainer?: object;
  legendBox?: object;
  legendText?: object;
  legalDisclaimer?: object;
}

export const BULLET_COLOR_0 = 'rgb(0, 157, 224)';
export const BULLET_COLOR_1 = 'rgb(118, 211, 255)';
export const BULLET_COLOR_2 = 'rgb(199, 237, 255)';

const pdfStyles: PDFStylesTypes = StyleSheet.create({
  body: {
    padding: 36,
    backgroundColor: '#C7EDFF',
  },
  header: {
    position: 'relative',
    marginBottom: 12,
  },
  logo: {
    height: 12,
    width: 186,
    marginBottom: 18,
  },
  productTitle: {
    fontSize: 12,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  headerSmall: {
    fontSize: 18,
    marginBottom: 12,
    color: '#002C77',
  },
  text: {
    fontSize: 10,
    marginBottom: 12,
  },
  recommendation: {
    backgroundColor: '#00968F',
    padding: 15,
    marginBottom: 13,
    marginTop: 13,
    borderRadius: 12,
  },
  recommendationHeader: {
    fontSize: 18,
    color: '#BED3E4',
    marginBottom: 12,
    textTransform: 'uppercase',
  },
  recommendationSubHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#76D3FF',
    textDecoration: 'underline',
    marginBottom: 6,
  },
  recommendationText: {
    fontSize: 10,
    color: 'white',
    marginBottom: 6,
  },
  legalDisclaimer: {
    fontSize: 8,
    fontStyle: 'italic',
    fontWeight: 'light',
    position: 'absolute',
    bottom: 45,
    left: 36,
    right: 36,
    textAlign: 'left',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 25,
    left: 0,
    right: 36,
    textAlign: 'right',
    color: 'grey',
  },
  sessionID: {
    position: 'absolute',
    fontSize: 10,
    bottom: 25,
    left: 36,
    right: 0,
    textAlign: 'left',
    color: 'grey',
  },
  chartContainer: {
    backgroundColor: 'white',
    padding: 18,
    marginBottom: 12,
    borderRadius: 12,
  },
  chart1: {
    height: 240,
    width: 360,
    alignSelf: 'center',
    marginBottom: 0,
  },
  chart2: {
    height: 240,
    width: 360,
    alignSelf: 'center',
    marginBottom: 36,
  },
  chartHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#00968F',
    textDecoration: 'underline',
    marginBottom: 6,
  },
  chartP: {
    fontSize: 10,
    marginBottom: 12,
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '10 0',
  },
  legendBox: {
    height: 20,
    width: 20,
    position: 'relative',
  },
  legendText: {
    marginRight: 20,
    paddingVertical: 3,
    position: 'relative',
    fontSize: 8,
  },
});

export default pdfStyles;
