import { useTranslation } from 'react-i18next';
import LanguageSelection from '../../components/LanguageSelection/LanguageSelection';
import logo from '../../media/mmb-logo.svg';
import styles from './Pages.module.scss';
import type { AvailableLanguages } from '../../types/availableLanguages';
import type LanguageChangeHandler from '../../types/languageChangeHandler';

interface HomeHeaderProps {
  availableLanguages: AvailableLanguages;
  currentLanguage: string;
  languageChangeHandler: LanguageChangeHandler;
}

const HomeHeader = ({
  availableLanguages,
  currentLanguage,
  languageChangeHandler,
}: HomeHeaderProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.homeHeader}>
      <img className={styles.logo} src={logo} alt={t('other.logo')} />
      {availableLanguages?.length > 1 && (
        <LanguageSelection
          availableLanguages={availableLanguages}
          currentLanguage={currentLanguage}
          languageChangeHandler={languageChangeHandler}
        />
      )}
    </div>
  );
};

export default HomeHeader;
