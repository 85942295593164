import styles from './Button.module.scss';

interface ButtonProps {
  className?: string;
  isDisabled?: boolean;
  onClick?: () => unknown;
  children: React.ReactNode;
  dataTestId?: string;
}

const Button = ({ className, isDisabled, onClick, dataTestId, children }: ButtonProps) => (
  <button
    className={`${styles.button} ${className}`}
    disabled={isDisabled}
    onClick={onClick}
    type="button"
    data-testid={dataTestId}
  >
    {children}
  </button>
);

Button.defaultProps = {
  className: '',
  isDisabled: null,
  onClick: null,
  dataTestId: null,
};

export default Button;
