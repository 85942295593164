import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { CHART_2_CONTENT } from './chartContent';
import type { ChartOptions } from '../../types/data';
import ChartChild from './ChartChild';

HighchartsMore(Highcharts);

const Chart2 = ({ options }: ChartOptions) => {
  const chart2Options = {
    ...options,
    xAxis: {
      ...options.xAxis,
      tickmarkPlacement: 'on',
      lineWidth: 0,
    },
  };
  return (
    <ChartChild
      options={chart2Options}
      pixelHeight={390}
      isShowLegend={false}
      content={CHART_2_CONTENT}
    />
  );
};

export default Chart2;
