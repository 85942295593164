const PLOT_OPTIONS = {
  series: {
    dataLabels: {
      enabled: true,
      style: {
        color: 'black',
      },
      allowOverlap: true,
    },
  },
  accessibility: {
    enabled: false,
  },
};

export default PLOT_OPTIONS;
