interface Codes {
  [key: string]: string;
}

const AVAILABLE_LANGUAGES_CODES: Codes = {
  'en-us': 'English',
  'es-es': 'Spanish',
  'it-it': 'Italian',
  'fr-fr': 'French',
};

export default AVAILABLE_LANGUAGES_CODES;
