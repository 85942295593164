/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';
import styles from './Section.module.scss';

interface SectionProps {
  setIsActive: () => void;
  isActive: boolean;
  title: string;
  desc: string;
  dataTestId: string;
}

const Section = ({ setIsActive, isActive, title, desc, dataTestId }: SectionProps) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div
      className={
        !animate
          ? `${styles.section} ${!isActive && styles.sectionExpanded}`
          : `${styles.section} ${styles.sectionFadeIn} ${isActive && styles.sectionExpanded}`
      }
      role="button"
      data-testid={dataTestId}
      onClick={setIsActive}
    >
      <strong>{`${title} - `}</strong>
      <i className={styles.sectionDesc}>{desc}</i>
    </div>
  );
};

export default Section;
