import CD from '../media/recommendations/CD.svg';
import EF from '../media/recommendations/EF.svg';
import MWL from '../media/recommendations/MWL.svg';
import OS from '../media/recommendations/OS.svg';
import PSAW from '../media/recommendations/PSAW.svg';
import SIAW from '../media/recommendations/SIAW.svg';
import type { DimensionItem } from '../types/dimensionItem';

const ITEMS = {
  dimensions: [MWL, PSAW, SIAW, OS, CD, EF].map((icon, index): DimensionItem => {
    const data = `recommendationsData.${index}.`;
    return {
      dimensionName: `${data}dimensionName`,
      icon,
      recommendation: [
        {
          title: `${data}recommendation.0.title`,
          wording: `${data}recommendation.0.wording`,
        },
        {
          title: `${data}recommendation.1.title`,
          wording: `${data}recommendation.1.wording`,
        },
        {
          title: `${data}recommendation.2.title`,
          wording: `${data}recommendation.2.wording`,
        },
      ],
    };
  }),
};

export default ITEMS;
