/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import styles from './Chart.module.scss';
import Chart1Legend from './Chart1Legend';
import type { ChartOptions } from '../../types/data';

interface ChartChildProps {
  options: ChartOptions;
  pixelHeight: number;
  isShowLegend: boolean;
  content: string[];
}

const ChartChild = ({ options, pixelHeight, isShowLegend, content }: ChartChildProps) => {
  const { t } = useTranslation();
  const [chartOptions, setChartOptions] = useState<object | null>(null);

  useEffect(() => {
    const childOptions = {
      ...options,
      plotOptions: {
        ...options.plotOptions,
        series: {
          dataLabels: {
            ...options.plotOptions?.series.dataLabels,
            enabled: false,
          },
        },
      },
    };
    setChartOptions(childOptions);
  }, [options]);

  const childProps = {
    style: {
      height: `calc(100vh - ${pixelHeight}px)`,
      flex: '100%',
      margin: window.innerWidth <= 415 ? '12px' : '36px 12px 12px',
    },
  };

  return (
    chartOptions && (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          containerProps={childProps}
        />
        {isShowLegend && <Chart1Legend />}
        <h4 className={styles.chartHeading}>{t('chart.resultsExplained')}</h4>
        {content.map((text: string, index: number) => (
          <p className={styles.chartP} key={index}>
            {t(text)}
          </p>
        ))}
      </div>
    )
  );
};

export default ChartChild;
