import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Modal.module.scss';
import Button from '../Button/Button';

interface ModalProps {
  content: {
    recommendation: { title: string; wording: string }[];
    icon: string;
    dimensionName: string;
  };
  modalClose: () => void;
  modalIncrement: () => void;
  modalDecrement: () => void;
  currentModal: number;
  maxLength: number;
}

const Modal = ({
  content: { recommendation, icon, dimensionName },
  modalClose,
  modalIncrement,
  modalDecrement,
  currentModal,
  maxLength,
}: ModalProps) => {
  const { t } = useTranslation();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const modalContent = recommendation.map(({ title, wording }) => (
    <div key={title}>
      <h4>{t(title)}</h4>
      <p>{t(wording)}</p>
    </div>
  ));

  const closeHandler = () => {
    setAnimate(false);
    setTimeout(() => {
      modalClose();
    }, 300);
  };

  return (
    <div className={styles.backdrop}>
      <div className={`${styles.modal} ${animate && styles.modalFadeIn}`}>
        <div className={styles.header}>
          <img className={styles.icon} src={icon} alt={t('other.logo')} />
          <h2>{t(dimensionName)}</h2>
        </div>
        <div className={styles.content}>
          <div>{modalContent}</div>
        </div>
        <div className={styles.footer}>
          <Button
            className={currentModal === 0 ? styles.modalButtonUnclickable : styles.modalButton}
            onClick={modalDecrement}
            isDisabled={currentModal === 0}
          >
            {t('other.previous')}
          </Button>
          <Button
            className={
              currentModal === maxLength ? styles.modalButtonUnclickable : styles.modalButton
            }
            onClick={modalIncrement}
            isDisabled={currentModal === maxLength}
          >
            {t('other.next')}
          </Button>
          <Button className={styles.modalButton} onClick={closeHandler}>
            {t('other.close')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
