/* eslint-disable react/forbid-dom-props */
import { Page, Text, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import logo from '../../media/mmb-logo.png';
import pdfStyles from './pdfStyles';
import PDFPageNumber from './PDFPageNumber';
import PDFHeader from './PDFHeader';

interface PDFPage2Props {
  sessionID: string;
  pngUrl: string;
}

const PDFPage2 = ({ sessionID, pngUrl }: PDFPage2Props) => {
  const { t } = useTranslation();
  return (
    <Page style={pdfStyles.body}>
      <PDFHeader />
      <div style={pdfStyles.chartContainer}>
        <Text style={pdfStyles.headerSmall}>{t('chart.cohortResults')}</Text>
        <Image style={pdfStyles.chart2} src={pngUrl} source={logo} />
        <Text style={pdfStyles.chartHeader}>{t('chart.resultsExplained')}</Text>
        <Text style={pdfStyles.chartP}>{t('chart.chart2.content0')}</Text>
        <Text style={pdfStyles.chartP}>{t('chart.chart2.content1')}</Text>
        <Text style={pdfStyles.chartP}>{t('chart.chart2.content2')}</Text>
      </div>
      <PDFPageNumber sessionID={sessionID} />
    </Page>
  );
};

export default PDFPage2;
