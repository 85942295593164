import { Image, Text, View } from '@react-pdf/renderer';
import APP_NAME from '../../constants/appName';
import pdfStyles from './pdfStyles';
import logo from '../../media/mmb-logo.png';

const PDFHeader = () => (
  <View style={pdfStyles.header}>
    <Image style={pdfStyles.logo} src={logo} fixed />
    <Text style={pdfStyles.productTitle} fixed>
      {APP_NAME}
    </Text>
  </View>
);

export default PDFHeader;
