import { useTranslation } from 'react-i18next';
import styles from './Chart.module.scss';

const Chart1Legend = () => {
  const { t } = useTranslation();
  return (
    <ul className={styles.legendContainer}>
      <li className={styles.legendBullet}>
        <div className={`${styles.dot} ${styles.dot0}`} />
        <div className={styles.legendText}>{t('other.strength')}</div>
      </li>
      <li className={styles.legendBullet}>
        <div className={`${styles.dot} ${styles.dot1}`} />
        <div className={styles.legendText}>{t('other.inLine')}</div>
      </li>
      <li className={styles.legendBullet}>
        <div className={`${styles.dot} ${styles.dot2}`} />
        <div className={styles.legendText}>{t('other.opportunity')}</div>
      </li>
    </ul>
  );
};

export default Chart1Legend;
