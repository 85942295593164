/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import styles from './Recommendations.module.scss';
import Rec from './Rec';
import Modal from '../../UI/Modal/Modal';
import ITEMS from '../../constants/recommendationsData';

const Recommendations = () => {
  const [modalDisplay, setModalDisplay] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [currentModal, setCurrentModal] = useState(0);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const { dimensions } = ITEMS;

  const recClickHandler = (index: number) => {
    setCurrentModal(index);
    setModalDisplay(!modalDisplay);
  };

  const modalCloseHandler = () => {
    setModalDisplay(false);
  };

  const modalIncrementHandler = () =>
    currentModal < dimensions.length - 1 && setCurrentModal(currentModal + 1);

  const modalDecrementHandler = () => currentModal > 0 && setCurrentModal(currentModal - 1);

  return (
    <div>
      {modalDisplay && (
        <Modal
          content={dimensions[currentModal]}
          modalClose={modalCloseHandler}
          modalIncrement={modalIncrementHandler}
          modalDecrement={modalDecrementHandler}
          currentModal={currentModal}
          maxLength={dimensions.length - 1}
        />
      )}
      <div className={`${styles.recContainer} ${animate && styles.recContainerFadeIn}`}>
        {dimensions.map(({ dimensionName, icon }, index) => (
          <div onClick={() => recClickHandler(index)} key={index} role="button">
            <Rec text={dimensionName} svg={icon} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recommendations;
