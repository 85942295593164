import { useTranslation } from 'react-i18next';
import styles from './Rec.module.scss';

interface RecProps {
  text: string;
  svg: string;
}

const Rec = ({ text, svg }: RecProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.rec}>
      <img className={styles.icon} src={svg} alt={t('other.logo')} />
      <br />
      {t(text)}
      <div className={styles.goCorner}>
        <div className={styles.goArrow}>→</div>
      </div>
    </div>
  );
};

export default Rec;
