const PAGE_LINKS = [
  {
    name: 'results.yourResults',
    pageLink: '',
  },
  {
    name: 'results.cohortResults',
    pageLink: 'cohort-results',
  },
  {
    name: 'results.recommendations',
    pageLink: 'recommendations',
  },
  {
    name: 'results.companyResources',
    pageLink: 'company-resources',
  },
];

export default PAGE_LINKS;
