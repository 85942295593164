export interface TranslatedPDFs {
  [index: string]: string;
}

const TRANSLATED_PDFS: TranslatedPDFs = {
  en: 'privacy-notice-en-us.pdf',
  es: 'privacy-notice-es-es.pdf',
  it: 'privacy-notice-it-it.pdf',
  fr: 'privacy-notice-fr-fr.pdf',
};

export default TRANSLATED_PDFS;
