type SECTIONStype = {
  title: string;
  description: string;
}[];

const SECTIONS: SECTIONStype = [];

for (let i = 0; i < 6; i += 1) {
  SECTIONS.push({
    title: `other.sectionDesc.${i}.title`,
    description: `other.sectionDesc.${i}.description`,
  });
}

export default SECTIONS;
