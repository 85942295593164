import { View, Text, Svg, Circle } from '@react-pdf/renderer';
import pdfStyles from './pdfStyles';

interface PDFLegendProps {
  text: string;
  color: string;
}

const PDFLegend = ({ text, color }: PDFLegendProps) => (
  <>
    <View style={pdfStyles.legendBox}>
      <Svg viewBox="0 0 0 0">
        <Circle cx="6" cy="6" r="6" fill={color} />
      </Svg>
    </View>
    <Text style={pdfStyles.legendText}>{text}</Text>
  </>
);

export default PDFLegend;
