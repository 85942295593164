import { memo } from 'react';
import { Document } from '@react-pdf/renderer';
import PDFPage1 from './PDFPage1';
import PDFPage2 from './PDFPage2';
import PDFPage3 from './PDFPage3';
import type CommonProps from '../../types/commonProps';

interface PDFfileProps extends CommonProps {
  sessionID: string;
  pngUrl1: string;
  pngUrl2: string;
  currentLanguage: string;
}

const PDFfile = ({
  pngUrl1,
  pngUrl2,
  strengths,
  inLines,
  opportunities,
  sessionID,
  currentLanguage,
}: PDFfileProps) => (
  <Document>
    <PDFPage1 sessionID={sessionID} pngUrl={pngUrl1} />
    <PDFPage2 sessionID={sessionID} pngUrl={pngUrl2} />
    <PDFPage3
      strengths={strengths}
      inLines={inLines}
      opportunities={opportunities}
      sessionID={sessionID}
      currentLanguage={currentLanguage}
    />
  </Document>
);

export default memo(PDFfile);
