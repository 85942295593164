import { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './Modal.module.scss';
import Button from '../Button/Button';

interface MessageModalProps {
  modalClose: () => void;
  sessionID: string;
}

const MessageModal = ({ modalClose, sessionID }: MessageModalProps) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const closeHandler = () => {
    setAnimate(false);
    setTimeout(() => {
      modalClose();
    }, 300);
  };

  return (
    <div className={styles.backdrop}>
      <div
        className={!animate ? styles.messageModal : `${styles.messageModal} ${styles.modalFadeIn}`}
      >
        <div className={styles.messageContent}>
          {sessionID && (
            <>
              <p>
                <Trans i18nKey="other.sessionId.note" />
              </p>
              <p>
                <Trans
                  i18nKey="other.sessionId.email"
                  values={{ link: 'analyticssupport@mercermarshbenefits.com' }}
                  components={{
                    anchor: <Link to="mailto:analyticssupport@mercermarshbenefits.com" />,
                  }}
                />
              </p>
              <p className={styles.sessionID}>
                <strong>{sessionID}</strong>
              </p>
            </>
          )}
        </div>
        <div className={styles.footer}>
          <Button className={styles.modalButton} onClick={closeHandler}>
            <Trans i18nKey="other.close" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
