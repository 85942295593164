import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { AvailableLanguages } from '../../types/availableLanguages';
import type LanguageChangeHandler from '../../types/languageChangeHandler';

const FORM_STYLING = { m: 0, minWidth: 120 };

interface LanguageSelectionProps {
  availableLanguages: AvailableLanguages;
  currentLanguage: string;
  languageChangeHandler: LanguageChangeHandler;
}

const LanguageSelection = ({
  availableLanguages,
  languageChangeHandler,
  currentLanguage,
}: LanguageSelectionProps) => {
  const { t } = useTranslation();

  const menuItems = availableLanguages.map(({ code, name }) => (
    <MenuItem key={name} value={code}>
      {name}
    </MenuItem>
  ));

  return (
    <div>
      <FormControl variant="standard" sx={FORM_STYLING}>
        <InputLabel id="simple-select-standard-label">{t('other.language')}</InputLabel>
        <Select
          labelId="simple-select-standard-label"
          id="simple-select-standard"
          data-testid="languageDropdown"
          value={currentLanguage}
          onChange={languageChangeHandler}
          label={t('other.language')}
        >
          {menuItems}
        </Select>
      </FormControl>
    </div>
  );
};

export default LanguageSelection;
