import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HcExporting from 'highcharts/modules/exporting';
import styles from '../../pages/HomePage/Pages.module.scss';
import PDFfile from './PDFfile';
import Button from '../../UI/Button/Button';
import PLOT_OPTIONS from './plotOptions';
import type { ChartOptions } from '../../types/data';
import type CommonProps from '../../types/commonProps';
import downloadIcon from '../../media/download.svg';
import { createChart, downloadPdf } from '../../utils/pdfFunctions';

HcExporting(Highcharts);

const MY_DIV = document.createElement('div');

interface PDFfileDownloadProps extends CommonProps {
  options: ChartOptions;
  sessionID: string;
  currentLanguage: string;
}

const PDFfileDownload = ({
  strengths,
  inLines,
  opportunities,
  options,
  sessionID,
  currentLanguage,
}: PDFfileDownloadProps) => {
  const { t } = useTranslation();
  const [animate, setAnimate] = useState(false);
  const [pngUrl1, setPngUrl1] = useState<string>('');
  const [pngUrl2, setPngUrl2] = useState<string>('');
  const [chart1Options, chart2Options] = options;

  chart1Options.plotOptions = { ...PLOT_OPTIONS };
  chart2Options.plotOptions = { ...PLOT_OPTIONS };

  const intArray = chart2Options.series[1].data.map((item: number) => Math.round(item));
  chart2Options.series[1].data = intArray;

  useEffect(() => {
    setAnimate(true);
  }, []);

  useEffect(() => {
    createChart(MY_DIV, chart1Options, setPngUrl1);
    createChart(MY_DIV, chart2Options, setPngUrl2);
    return () => {
      MY_DIV.remove();
    };
  }, [options]);

  return (
    <Button
      className={!animate ? styles.pageButton : `${styles.pageButtonFadeIn} ${styles.pageButton}`}
      onClick={() =>
        downloadPdf(
          <PDFfile
            pngUrl1={pngUrl1}
            pngUrl2={pngUrl2}
            strengths={strengths}
            inLines={inLines}
            opportunities={opportunities}
            sessionID={sessionID}
            currentLanguage={currentLanguage}
          />,
          t('other.personalRecommendations')
        )
      }
    >
      <img src={downloadIcon} className={styles.icon} alt="Download" /> {t('other.PDF')}
    </Button>
  );
};

export default PDFfileDownload;
