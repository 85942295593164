import { Trans, useTranslation } from 'react-i18next';
import styles from './Results.module.scss';
import Recommendations from '../Recommendations/Recommendations';
import type CommonProps from '../../types/commonProps';
import translateCategories from '../../utils/translateCategories';

interface ResultsProps extends CommonProps {
  isEap: boolean;
  showCompanyTab: boolean | number;
  currentLanguage: string;
}

const Results = ({
  strengths,
  inLines,
  opportunities,
  isEap,
  showCompanyTab,
  currentLanguage,
}: ResultsProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.results}>
      <p className={styles.recP}>
        {strengths?.length > 0 && (
          <Trans t={t}>
            {t('results.styled.high', {
              categories: translateCategories(t, strengths, currentLanguage),
            })}
            <strong className={styles.dimension} />{' '}
          </Trans>
        )}
        {opportunities?.length > 0 && (
          <Trans t={t}>
            {t('results.styled.low', {
              categories: translateCategories(t, opportunities, currentLanguage),
            })}
            <strong className={styles.dimension} />{' '}
          </Trans>
        )}
        {inLines?.length > 0 && (
          <Trans t={t}>
            {t('results.styled.immediate', {
              categories: translateCategories(t, inLines, currentLanguage),
            })}
            <strong className={styles.dimension} />{' '}
          </Trans>
        )}{' '}
        {t('results.based')}
      </p>
      {(isEap || showCompanyTab) && showCompanyTab !== 0 && (
        <p className={styles.recP}>{t('results.check')} </p>
      )}
      <p className={styles.recP}>{t(isEap ? 'results.speak' : 'results.talk')} </p>
      <h4 className={styles.recHeading}>{t('results.some')}</h4>
      <p className={styles.disclaimer}>{t('results.theFollowing')}</p>
      <Recommendations />
    </div>
  );
};

export default Results;
