import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './App.module.scss';
import LoadingSpinner from './UI/LoadingSpinner/LoadingSpinner';
import defaultData from './pages/data/defaultData.json';
import ThankYou from './UI/ThankYou/ThankYou';
import HomePage from './pages/HomePage/HomePage';
import CHART_CATEGORIES from './constants/chartCategories';
import type { ChartOptions } from './types/data';
import availableLanguagesDataTransform from './utils/availableLanguagesDataTransform';
import DEFAULT_LANGUAGE from './constants/lanaguage';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(defaultData);
  const [error, setError] = useState<string[] | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const token = window.location.search.split('=')[1];

    const dataFetch = async () => {
      if (process.env.REACT_APP_USE_DUMMY_DATA) {
        setData(defaultData);
        return setIsLoading(false);
      }
      try {
        const response = await fetch(process.env.REACT_APP_NODE_API_URL!, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if ([500, 404].includes(response.status)) {
          console.error('MongoDB Query Error');
          return setError([
            t('survey.thankYou', { lng: DEFAULT_LANGUAGE }),
            t('survey.close', { lng: DEFAULT_LANGUAGE }),
          ]);
        }
        if (response.status === 503) {
          console.error('Client - No Employee report');
          return setError([
            t('survey.thankYou', { lng: DEFAULT_LANGUAGE }),
            t('survey.close', { lng: DEFAULT_LANGUAGE }),
          ]);
        }
        if (response.status === 406) {
          console.error('User has not answered any questions');
          return setError([
            t('survey.didntAnswer', { lng: DEFAULT_LANGUAGE }),
            t('survey.close', { lng: DEFAULT_LANGUAGE }),
          ]);
        }
        if (response.status === 501) {
          console.error('User has not given permission.');
          return setError([
            t('survey.noPermission', { lng: DEFAULT_LANGUAGE }),
            t('survey.close', { lng: DEFAULT_LANGUAGE }),
          ]);
        }
        const responseData = await response.json();
        setData(responseData);
        return setIsLoading(false);
      } catch {
        console.error('MongoDB Query Error');
        setIsLoading(false);
        return setError([
          t('survey.thankYou', { lng: DEFAULT_LANGUAGE }),
          t('survey.close', { lng: DEFAULT_LANGUAGE }),
        ]);
      }
    };

    dataFetch();
  }, []);

  const translatedOptions =
    !isLoading &&
    !error &&
    data.chartOptions.map((element: ChartOptions) => ({
      ...element,
      title: { text: null },
      xAxis: {
        categories: element.xAxis.categories.map((category: string) =>
          t(CHART_CATEGORIES[category] || '')
        ),
      },
      series: element.series.map((series: { name: string; data?: object[]; color?: string }) => ({
        ...series,
        name: t(CHART_CATEGORIES[series.name] || ''),
      })),
    }));

  const availableLanguages = data && availableLanguagesDataTransform(data.AvailableLanguages);

  const preferredLanguageCode = data?.preferredLanguage?.slice(0, 2);

  return (
    <div className={styles.page}>
      {isLoading && <LoadingSpinner message="" />}
      {!isLoading && error && <ThankYou errors={error} />}
      {!isLoading && !error && (
        <HomePage
          strengths={data.strengths}
          inLines={data.inLines}
          opportunities={data.opportunities}
          options={translatedOptions}
          clientInfoFields={data.ClientInfoFields}
          isEap={data.EAP}
          sessionID={data.sessionID}
          availableLanguages={availableLanguages}
          preferredLanguageCode={preferredLanguageCode}
        />
      )}
    </div>
  );
}

export default App;
