import AVAILABLE_LANGUAGES_CODES from '../constants/availableLanguagesCodes';
import type { AvailableLanguages } from '../types/availableLanguages';

const availableLanguagesDataTransform = (data: string[]) => {
  const output: AvailableLanguages = [];
  data.forEach((code: string) => {
    const languageName = AVAILABLE_LANGUAGES_CODES[code] || null;
    if (languageName) {
      output.push({
        code: code.slice(0, 2),
        name: languageName,
      });
    }
  });
  return output;
};

export default availableLanguagesDataTransform;
