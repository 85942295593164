import styles from './LoadingSpinner.module.scss';

interface LoadingSpinnerProps {
  message: string;
}

const LoadingSpinner = ({ message }: LoadingSpinnerProps) => {
  const tiles = [];
  for (let i = 0; i < 9; i += 1) {
    tiles.push(<div key={i} />);
  }

  return (
    <div className={styles.backdrop} data-testid="loading-spinner">
      <div className={styles.ldsGrid}>{tiles}</div>
      <div className={styles.loadingMessage}>{message}</div>
    </div>
  );
};

export default LoadingSpinner;
