/* eslint-disable react/forbid-dom-props */
import { Page, View, Text, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import logo from '../../media/mmb-logo.png';
import pdfStyles, { BULLET_COLOR_0, BULLET_COLOR_1, BULLET_COLOR_2 } from './pdfStyles';
import PDFLegend from './PDFLegend';
import PDFPageNumber from './PDFPageNumber';
import PDFHeader from './PDFHeader';

interface PDFPage1Props {
  sessionID: string;
  pngUrl: string;
}

const PDFPage1 = ({ sessionID, pngUrl }: PDFPage1Props) => {
  const { t } = useTranslation();
  return (
    <Page style={pdfStyles.body}>
      <PDFHeader />
      <div style={pdfStyles.chartContainer}>
        <Text style={pdfStyles.headerSmall}>{t('results.yourResults')}</Text>
        <Image style={pdfStyles.chart1} src={pngUrl} source={logo} />
        <View style={pdfStyles.legendContainer}>
          <PDFLegend text={t('other.strength')} color={BULLET_COLOR_0} />
          <PDFLegend text={t('other.inLine')} color={BULLET_COLOR_1} />
          <PDFLegend text={t('other.opportunity')} color={BULLET_COLOR_2} />
        </View>
        <Text style={pdfStyles.chartHeader}>{t('chart.resultsExplained')}</Text>
        <Text style={pdfStyles.chartP}>{t('chart.chart1.content0')}</Text>
        <Text style={pdfStyles.chartP}>{t('chart.chart1.content1')}</Text>
      </div>
      <PDFPageNumber sessionID={sessionID} />
    </Page>
  );
};

export default PDFPage1;
