/* eslint-disable react/no-array-index-key */
import styles from './RecommendationText.module.scss';

interface RecommendationsTextProps {
  clientInfoFields: string[];
}

const RecommendationText = ({ clientInfoFields }: RecommendationsTextProps) => (
  <div className={styles.companyInfo}>
    {clientInfoFields.map((blurb, index) => (
      <p key={index}>{blurb}</p>
    ))}
  </div>
);

export default RecommendationText;
