import { useTranslation } from 'react-i18next';
import styles from './ThankYou.module.scss';
import survey from '../../media/Clipboard-Pink-Reverse.svg';
import logo from '../../media/mmb-logo-white.svg';

interface ThankYouProps {
  errors?: string[];
}

const ThankYou = ({ errors }: ThankYouProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.backdrop} data-testid="thank-you">
      <img className={styles.logo} src={logo} alt={t('other.logo')} />
      <img className={styles.graphic} src={survey} alt={t('other.survey')} />
      <div className={styles.message}>{errors && errors[0]}</div>
      <div className={styles.message2}>{errors && errors[1]}</div>
    </div>
  );
};

ThankYou.defaultProps = {
  errors: ['error1', 'error2'],
};

export default ThankYou;
