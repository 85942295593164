import { Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import pdfStyles from './pdfStyles';

interface PDFPageNumberProps {
  sessionID: string;
}

const PDFPageNumber = ({ sessionID }: PDFPageNumberProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Text id="disclaimer" style={pdfStyles.legalDisclaimer} fixed>
        {t('other.disclaimer')}
      </Text>
      <Text style={pdfStyles.sessionID} fixed>
        {t('other.sessionID', { sessionID })}
      </Text>
      <Text
        style={pdfStyles.pageNumber}
        render={({ pageNumber, totalPages }) => `${t('other.page')} ${pageNumber} / ${totalPages}`}
        fixed
      />
    </>
  );
};

export default PDFPageNumber;
