import type { TFunction } from 'i18next';
import ITEMS from '../../constants/recommendationsData';
import DEFAULT_LANGUAGE from '../../constants/lanaguage';

const ENGLISH_LANGUAGE = { lng: DEFAULT_LANGUAGE };

const dimensionsInEnglish = (t: TFunction) =>
  ITEMS.dimensions.map((item) => ({
    ...item,
    dimensionName: t(item.dimensionName, ENGLISH_LANGUAGE),
    recommendation: item.recommendation.map((rec) => ({
      title: t(rec.title, ENGLISH_LANGUAGE),
      wording: t(rec.wording, ENGLISH_LANGUAGE),
    })),
  }));

export default dimensionsInEnglish;
