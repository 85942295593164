import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import TRANSLATION_EN from './en.json';
import TRANSLATION_ES from './es.json';
import TRANSLATION_IT from './it.json';
import TRANSLATION_FR from './fr.json';
import DEFAULT_LANGUAGE from '../constants/lanaguage';

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: TRANSLATION_EN,
    },
    es: {
      translation: TRANSLATION_ES,
    },
    it: {
      translation: TRANSLATION_IT,
    },
    fr: {
      translation: TRANSLATION_FR,
    },
  },
});

export default i18n;
